const Footer = () => {

    return (
        <footer className="footer-section">
            <div className="section text-center">
                <div>© Copyright 2024 VOXA | All rights reserved.</div>
            </div>
        </footer>
    );
}

export default Footer;