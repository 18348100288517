const Menu = () => {
    return (
        <nav className="navbar navbar-expand-lg menu-frame">
            <div className="container-fluid">
                <a className="navbar-brand fw-bold" href="/">
                    <img alt="VOXA Logo" className="menu-logo" src="/images/fulllogo.png" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item d-md-block d-lg-none my-2">
                            <a id="menu-phone-whatsapp" className="menu-href menu-href-social" aria-label="Whatsapp" href="https://wa.me/+85295358088" target="_blank">
                                <i className="bi bi-whatsapp"></i>
                            </a>
                            <a className="menu-href menu-href-social" aria-label="Send Email to Me" href="mailto:dev@getvoxa.com">
                                <i className="bi bi-envelope"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="menu-href-frame d-none d-lg-block">
                    <ul className="menu-icon navbar-nav list-unstyled d-flex justify-content-end">
                        <li className="ms-4 me-4">
                            <a id="menu-instagram" className="menu-href" aria-label="Send Email to Me" href="mailto:dev@getvoxa.com" target="_blank">
                                <i className="bi bi-envelope"></i>
                            </a>
                        </li>
                        <li className="ms-4 me-4">
                            <a id="menu-whatsapp" className="menu-href" aria-label="Whatsapp" href="https://wa.me/+85295358088" target="_blank">
                                <i className="bi bi-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Menu;