import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';


const Home = () => {

  useEffect(() => {

  }, []);

  return (
    <>
      <div id="home" className="section">
        <div className="row mt-3">
          <div className="col-12 col-md-6 p-3">
            <div className="px-3 mt-3">
              <p className="text-uppercase">Connect Green, Share Smart.</p>
              <h1 className="text-uppercase">Discover VOXA</h1>
              <p className="mt-5">At VOXA, we are dedicated to transforming the way you share your professional identity. With our digital business cards, you can effortlessly connect with others, whether through a convenient digital card or a physical card featuring a QR code or NFC, making your networking limitless.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 px-3">
            <img alt="homepage" className="d-block w-100 r-3 p-3" src="images/cover1.webp" />
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="section">
          <div className="row">
            <div className="col-12 col-md-12 p-3">
              <div className="p-3 mt-3">
                <p className="text-uppercase">ABOUT</p>
                <h1 className="text-uppercase">Our Mission</h1>
                <p className="mt-5">Our mission is to provide innovative and convenient solutions that help you build connections more effectively. With our user-friendly content management system, you can update your information anytime, ensuring your contacts always have the latest details and keeping your professional image at its best.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="row text-center">
          <div className="p-3 mt-3">
            <h1 className="text-uppercase">Our Products</h1>

            <div className="row mt-5">
              <div className="col-12 col-md-4 p-3 position-relative main-image-hover-container">
                <a className="main-href" href="/">
                  <div className="w-100 h-100 r-3 p-3 bg-white main-image-hover">
                    <i className="bi bi-laptop fs-1"></i>
                    <h5 className="mt-3">Digital Cards</h5>
                    <p className="mt-4">Store and share your contact information effortlessly from your mobile wallet — fast and environmentally friendly.</p>
                  </div>
                  <div className="main-image-hover-overlay">
                    <div className="fs-3 fw-bold main-fontcolor"><i className="bi bi-hand-index fs-1 main-color"></i></div>
                  </div>
                </a>
              </div>

              <div className="col-12 col-md-4 p-3 position-relative main-image-hover-container">
                <a className="main-href" href="/">
                  <div className="w-100 h-100 r-3 p-3 bg-white main-image-hover">
                    <i className="bi bi-phone fs-1"></i>
                    <h5 className="mt-3">Physical Cards</h5>
                    <p className="mt-4">Our cards feature a QR code or NFC chip, linking to your personalized website for easy access to your information.</p>
                  </div>
                  <div className="main-image-hover-overlay">
                    <div className="fs-3 fw-bold main-fontcolor"><i className="bi bi-hand-index fs-1 main-color"></i></div>
                  </div>
                </a>
              </div>

              <div className="col-12 col-md-4 p-3 position-relative main-image-hover-container">
                <a className="main-href" href="/">
                  <div className="w-100 h-100 r-3 p-3 bg-white main-image-hover">
                    <i className="bi bi-pie-chart fs-1"></i>
                    <h5 className="mt-3">Content Management System</h5>
                    <p className="mt-4">Easily update your card information anytime with our user-friendly CMS, ensuring your contacts always have the latest details.</p>
                  </div>
                  <div className="main-image-hover-overlay">
                    <div className="fs-3 fw-bold main-fontcolor"><i className="bi bi-hand-index fs-1 main-color"></i></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div id="contact" className="bg-white">
        <div className="section">
          <div className="row">
            <div className="col-12 col-md-6 p-3 mt-3">
              <div className="p-3 mt-3">
                <p className="text-uppercase">Get in Touch</p>
                <h1 className="text-uppercase">CONTACT ME</h1>

                <div className="d-flex flex-row bd-highlight mt-5 align-items-center">
                  <div><i className="bi bi-whatsapp fs-1 main-color"></i></div>
                  <div className="px-4 fs-5">
                    <a className="main-href" href="https://wa.me/+85295358088" target="_blank">+852 9535 8088</a>
                  </div>
                </div>

                <div className="d-flex flex-row bd-highlight mt-3 align-items-center">
                  <div><i className="bi bi-envelope fs-1 main-color"></i></div>
                  <div className="px-4 fs-5">
                    <a className="main-href" href="mailto:dev@getvoxa.com" target="_blank">dev@getvoxa.com</a>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <img className="d-block w-100 r-3 p-3" src="images/contact.webp" alt="kelvin chan" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Home;